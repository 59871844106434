<template>
  <v-container>
    <v-row class="my-4">
      <v-col>
        <h4 class="title">Manage Carousels</h4>
      </v-col>
    </v-row>
    <v-row justify="end" class="my-2">
      <v-col class="text-right">
        <v-btn fab small color="accent" to="edit/">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="10"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:body="props">
            <draggable
              :list="props.items"
              tag="tbody"
              :move="moved"
              @change="changeOrder"
            >
              <tr v-for="(item, index) in props.items" :key="index">
                <td>
                  <v-icon small class="handle-icon">mdi-arrow-all</v-icon>
                </td>
                <td>{{ item.title }}</td>
                <td class="text-center">
                  <v-chip small>
                    {{ item.carousel_type }}
                  </v-chip>
                </td>
                <td class="text-center">
                  <v-icon :color="item.visible ? 'success' : 'error'">{{
                    item.visible ? 'mdi-check' : 'mdi-close'
                  }}</v-icon>
                </td>
                <td class="text-center">
                  <v-icon :color="item.dismissible ? 'success' : 'error'">{{
                    item.dismissible ? 'mdi-check' : 'mdi-close'
                  }}</v-icon>
                </td>
                <td class="text-center">
                  <v-icon :color="item.can_subscribe ? 'success' : 'error'">{{
                    item.can_subscribe ? 'mdi-check' : 'mdi-close'
                  }}</v-icon>
                </td>
                <td class="text-center">
                  <v-btn
                    icon
                    color="primary"
                    :to="'/admin/carousel/edit/' + item.id"
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon color="error">
                    <v-icon small @click="onDelete(item.id)">delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteAlert" persistent max-width="360">
      <v-card>
        <v-card-title>Warning</v-card-title>
        <v-card-text
          >Are you sure you want to delete this carousel?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" @click="deleteAlert = false">
            No
          </v-btn>
          <v-btn color="red" class="white--text" @click="deleteCarousel">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable
  },
  data() {
    return {
      cTypes: [
        'product_content',
        'favorites_restaurants',
        'available_now_producers',
        'restaurants',
        'new_products',
        'popular_products',
        'last_calls'
      ],
      headers: [
        { text: '', value: '', sortable: false },
        { text: 'Title', value: 'title', align: 'start', sortable: false },
        {
          text: 'Type',
          value: 'carousel_type',
          align: 'center',
          sortable: false
        },
        { text: 'Visible', value: 'visible', align: 'center', sortable: false },
        {
          text: 'Dismissible',
          value: 'dismissible',
          align: 'center',
          sortable: false
        },
        {
          text: 'Subscribe',
          value: 'can_subscribe',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      list: [],
      tempList: [],
      selectedCarouselID: null,
      deleteAlert: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      carouselList: 'carouselList'
    })
  },
  async mounted() {
    this.getCarouselList()
  },
  methods: {
    changeOrder() {
      this.list = this.tempList.map((item, k) => ({ ...item, order: k }))
      this.$store.dispatch('updateCarousels', { list: this.list })
    },
    moved(e) {
      this.tempList = e.relatedContext.list
    },
    onDelete(carouselID) {
      this.selectedCarouselID = carouselID
      this.deleteAlert = true
    },
    deleteCarousel() {
      this.deleteAlert = false
      const that = this
      if (!this.selectedCarouselID) return
      this.$store
        .dispatch('deleteCarousel', this.selectedCarouselID)
        .then(() => {
          that.getCarouselList()
        })
    },
    async getCarouselList() {
      await this.$store.dispatch('getCarousels')
      this.list = this.carouselList.sort((a, b) => a.order - b.order)
    }
  }
}
</script>
<style>
.handle-icon {
  cursor: pointer;
}
</style>
